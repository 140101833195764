import React from "react"
import Header from "./header"
import "./layout.css"

const Layout = ({ children, headerData }) => {
  return (
    <>
      <Header headerData={headerData}/>
      <div
        style={{
        }}
      >
        <main>{children}</main>
      </div>
    </>
  )
}

export default Layout
