import { Link } from "gatsby"
import React from "react"

const Header = (props) => {
  const { headerData } = props;

  return (
    <header
      style={{
        marginBottom: `1.45rem`,
      }}
    >
      <div
        style={{
        }}
      >
        <h1>
          <Link to="/">
            {headerData.name}
          </Link>
        </h1>

        <div className="bio">
          {headerData.description}
        </div>
      </div>
    </header>
  )
}
export default Header
