import React from "react"
import { Tooltip } from 'react-tooltip'
import "./socialLinks.css";
import 'react-tooltip/dist/react-tooltip.css'

const SocialLinks = (props) => {
  const { svgFiles, links } = props;

  return (
    <div className="social-links">
      {links.map((link, i) => {
        // fetches the image based on the value that that is set on the appropriate json element
        const iconForLink = svgFiles.edges.find(element => {
          return element.node.name === link.filename
        });

        if (iconForLink) {
          return (
            <React.Fragment key={i} >
              <a id={link.name} target="_blank" rel="noopener noreferrer" href={link.url} data-tooltip-content={link.tooltip}>
                <img src={iconForLink.node.publicURL} alt=""/>
              </a>
              <Tooltip anchorId={link.name} place="bottom" offset={10} />
            </React.Fragment>
          )
        }

        return <React.Fragment key={i} />
      })}
    </div>
  )
}
export default SocialLinks;